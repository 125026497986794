.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-margin {
  margin: 100px;
}

body{
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%),  url(/public/assets/loginbg.jpg) no-repeat;
  background-size:cover;
  height:100vh;
  width:100vw;
  background-attachment: fixed;
  background-position: center center; */
  background: #F3F6F9;
}
div{
  margin:0px;
  padding:0px;
}

.header{
	background: #F3F6F9;
	box-shadow: 0px 0px 4px 0px rgba(20, 20, 20, 0.10);
	display: flex;
	height: 66px;
	padding: 10px 66px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
}
.header-right {
	display: flex;
	gap: 20px;
	align-items: center;
}
.search-input{
  width:300px;
}
.user-sec{
  display: flex;
  align-items: center;
  gap: 10px;
}
.user{
  white-space: nowrap;
}
.main-container{
  padding:16px 66px;
  width:100%;
  /* height:100%; */
}
.card{
  border: 1px solid #CCC;
  background: #FFF;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius:0px;
  margin-bottom:24px;
}
.card p{
  color: #141414;
  /* font-family: Outfit; */
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card-title{
  border-bottom: 1px solid #ECEAEB;
  display: flex;
  padding: 5px 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #141414;
  /* font-family: Outfit; */
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.page-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-title h2{
  color: #141414;
  /* font-family: Outfit; */
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin:16px 0px 32px 0px;
}
.card-content{
  display: flex;
  /* padding: 8px 0px; */
  justify-content: space-between;
  align-items: center;
  width:100%;
  gap:20px;
}
.card-content .dropdown-toggle{
  display: flex;
  align-items: center;
  color: #37A0EA;
}

.card-content p{
  color: #999;
  /* font-family: Outfit; */
  font-size: 18.726px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.carousel-container{
  margin-bottom: 20px;
}
.slick-prev{
  left: 0px!important;
  width: 80px!important;
  height: 100%!important;
  background: linear-gradient(90deg, rgba(20, 20, 20, 0.5) 50%, rgba(20, 20, 20, 0) 100%)!important;
  z-index: 1;
}
.slick-next{
  right:0px!important;
  width: 80px!important;
  height: 100%!important;
  background: linear-gradient(270deg, rgba(20, 20, 20, 0.5) 50%, rgba(20, 20, 20, 0) 100%)!important;
  z-index: 1;
}
.slick-prev.slick-disabled, .slick-next.slick-disabled{
  background: none !important;
}
.img-container{
  height: 145px;
}
.img-container img{
  width:100%;
  height:100%;
  border-radius:6px;
  object-fit: cover;
  object-position: center;
}
.slick-initialized .slick-slide{
  margin:0px 6px;
  border-radius:6px;
}
.slick-list{
  padding:10px 0px 5px 0px!important;
}
.slick-slide{
  /* width:auto!important; */
  height:145px;
  display:flex;
}
.slick-slide >div{
  /* width:auto!important; */
  object-fit: cover;
  object-position: center;
  display:flex;

}
.slick-track{
  display:flex!important;
  float: left;
}
.stream-content{
  width:100%;
}
.card-content .btn-group{
  /* width: 300px; */
}
.current .btn-group{
  width: 300px;
}
.card-content .dropdown-toggle{
  justify-content: end;
  width: 33px;
  display: inline-flex;
  color: #000!important;
}
.btn-group .dropdown-toggle::after, .dropdown-caret-none::after{
  display:none;
}
.btn-group:focus-visible{
  outline:none;
}
.btn-group .btn-check:focus+.btn, .btn-group .btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn-group .btn{
  color: #37A0EA;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  /* justify-content: end; */
}
.btn-primary, .btn-primary:hover{
  border-radius: 5px;
  background: #37A0EA;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border:none;
  padding: 12px 24px;
}
.dropdown-menu{
  /* border:none; */
  margin:0px;
  padding:0px;
  /* padding: 12px 24px; */
}
.dropdown-item{
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
}
.dropdown-item, .dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: transparent; /*#e9ecef; */
  /* border-bottom: 1px solid #ccc; */
}
.dropdown-item:hover {
  background-color: #e9ecef;
}
.r-marginTop-156q2ks {
  margin-top: 0px;
}
.r-height-eu3ka{
  color: #757575;
  font-family: 'Outfit';
}
.r-borderColor-34efmp{
  border: 1px solid #BDBDBD;
}
.css-view-175oi2r{
  /* gap:24px; */

}
.r-backgroundColor-x9geu1{
 padding: 12px 24px;
}
.r-height-l2m9p7 {
  height: unset;
}
.css-text-146c3p1{
  font-family: 'Outfit';
  font-size : 16px;
}
#root{
  height:100%;
  width:100%;
  overflow-x: hidden;
}

.loginscreen{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%),  url(/public/assets/loginbg.jpg) no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
.loginbg{
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%),  url(/src/assets/loginbg.jpg) no-repeat;
  background-size:cover;
  height:100vh;
  width:100vw; */
  display: inline-flex;
  /* padding: 92px 201.667px 91.667px 272px; */
   /* padding: 0px 200px; */
  justify-content: space-evenly;
  align-items: center;
  /* gap: 15%; */
  height:100%;
  width:100%;
}
.loginbox{
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid #FFF;
  background: rgba(17, 17, 17, 0.50);
  backdrop-filter: blur(17.33333396911621px);
  padding: 25px 50px;
  color: #FFF;
}
.loginbox h3{
  color: #FFF;
  /* font-family: Outfit; */
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
}
.loginbox .amplify-label{
  color: #FFF;
}
.loginbox .amplify-input{
  background: #FFF;
}
.logo{
  max-width:400px;
  height:182px;
}
.amplify-tabs__item{
  border:none!important;
  border-style:none!important;
  border-width:0px!important;
  font-size: 20px;
}
.amplify-tabs__list--top {
  --internal-item-border-width: 0px;
  --internal-item-margin-start: 0px;
  --internal-item-margin-end: 0px;
}
.amplify-heading {
  color: #fff;
  font-size: 1.4em!important;
}
.page-loader-container{
  display: flex;
  justify-content:center;
  align-items: center;
  height:100vh;
  width:100vw;
}
.amplify-loader circle:last-of-type {
  stroke: #37A0EA;
}
.preview-stream-container{
  background:#000000;
}
.player-close{
  position: absolute;
  width: 100%;
  z-index: 1;
}
.modal-close{
  position: absolute;
  width: auto;
  z-index: 1;
  top: 20px;
  right: 40px;
}
.modal-close .btn-close:focus-visible {
  outline: none;
}
.preview-stream-container .btn-close{
  height: auto;
  width: auto;
  opacity: 1;
  background: transparent;
  border: 0px;
  float: right;
  margin-top: 20px;
  margin-right: 40px;
}
.preview-stream-container .btn-close:focus-visible {
  outline: none;
}
.btn-close .fa{
  font-size: 30px;
  cursor: pointer;
  color: #fff;
  display: block;
}
[data-amplify-authenticator] [data-amplify-form] {
  padding: 5% 10%;
}
[data-amplify-authenticator] [data-amplify-router] {
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid #FFF;
  background: rgba(17, 17, 17, 0.50);
  backdrop-filter: blur(17.33333396911621px);
}
.amplify-input:focus {
  border-color: #37A0EA;
}
.amplify-label{
  color: #FFF;
  /* font-family: Outfit; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.2px;
}
.amplify-input, .amplify-button{
  background:#ffffff!important;
}

.amplify-authenticator__subtitle {
  color: #757575;
}

.amplify-alert--error{
  color:rgba(243, 2, 2, 0.8);
}
.amplify-alert__dismiss{
  color:#000;
  background: transparent!important;
}
.amplify-button--primary{
  border-radius: 5px!important;
  background: #37A0EA!important;
}
[data-amplify-authenticator] [data-amplify-footer] .amplify-button--link{
  background:none!important;
  color:#ffffff!important;
}
/* .amplify-field-group__outer-end{
  background:#ffffff;
} */

.upload-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  border: 2px solid grey;
  border-style: dashed;
  overflow: hidden;
  padding: 20px;
}
.upload-wrapper label span{
  color:#37A0EA;
}
.inputfile {
  position: absolute;
  height: 100px;
  opacity: 0;
  overflow: hidden;
  /* z-index: 0; */
  width: 100%;
}
.inputfile + label {
  display: flex;
  height: 100%;
  /* width: 100%; */
  cursor: pointer !important;
  gap: 10px;
}
.uppy-size--md .uppy-Dashboard-AddFiles-title{
  font-size:20px!important;
  font-family: 'Outfit';
}
.uppy-Dashboard-AddFiles-info{
  display:none!important;
}
[data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  height: 100%;
  margin: 0px;
  border: 2px dashed #ccc !important;
  border-radius: 3px;
}
.r-fontSize-adyw6z {
  font-size: 20px !important;
}
.titlebox{
  width: auto;
  white-space: nowrap;
}
@media (min-width: 320px) and (max-width: 767px) {

  .loginbg{
    padding: 0px;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    flex-direction: column;
    width: 100%;
  }
  .loginbox{
    /* margin: 16px;
    padding: 25px  */
  }
  .logo {
    width:100%;
    padding: 40px;
  }
  .logo img{
    width: 100%;
    height: 100px;
  }

  .main-container{
    padding:16px;
  }
  .card-content {
    flex-direction: column;
    align-items: flex-end;
  }
  .titlebox{
    width:100%;
  }
  .header{
    padding:20px;
    flex-direction: column;
    height: auto;
  }
  .header-right{
    flex-direction:column;
    margin-top:16px;
    width:100%;
  }
  .user{
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-sec{
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  .dropdown-menu[data-bs-popper] {
    left: -99px;
  }
  .card-content .dropdown-menu[data-bs-popper] {
    left: 40px;
  }
  .current .card-content .dropdown-menu {
    left: -120px;
  }
  .r-width-1t5bsuq {
    width: calc(100% - 32px);
  }
  .r-width-9khbgq {
    width: calc(100% - 32px);
  }
  .uppy-Dashboard-inner{
    width: 100%!important;
    height: auto!important;
  }
  #uppyInline{
    width:100%!important;
  }
  [data-amplify-authenticator] {
    width: calc(100% - 32px);
    display:block;
  }
  .r-padding-1xutcf9 {
    padding: 20px;
  }
  .modal-close {
    right: 20px;
  }
  .card-content .btn-group {
    width: auto;
  }
}




.logo-customizable {
  max-width: 60%;
  max-height: 30%;
}

.banner-customizable {
  padding: 25px 0px 25px 0px;
  background-color: lightgray;
}

.label-customizable {
  font-weight: 400;
}

.textDescription-customizable {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  font-size: 16px;
}

.idpDescription-customizable {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  font-size: 16px;
}

.legalText-customizable {
  color: #747474;
  font-size: 11px;
}

.submitButton-customizable {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: #337ab7;
}

.submitButton-customizable:hover {
  color: #fff;
  background-color: #286090;
}

.errorMessage-customizable {
  padding: 5px;
  font-size: 14px;
  width: 100%;
  background: #F5F5F5;
  border: 2px solid #D64958;
  color: #D64958;
}

.inputField-customizable {
  width: 100%;
  height: 34px;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
}

.inputField-customizable:focus {
  border-color: #66afe9;
  outline: 0;
}

.idpButton-customizable {
  height: 40px;
  width: 100%;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.idpButton-customizable:hover {
  color: #fff;
  background-color: #31b0d5;
}

.socialButton-customizable {
  border-radius: 2px;
  height: 40px;
  margin-bottom: 15px;
  padding: 1px;
  text-align: left;
  width: 100%;
}

.redirect-customizable {
  text-align: center;
}

.passwordCheck-notValid-customizable {
  color: #DF3312;
}

.passwordCheck-valid-customizable {
  color: #19BF00;
}

.background-customizable {
  background-color: #fff;
}
